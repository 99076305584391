import React from "react";

export const Espectaculos = (props) => {
    return (
        <div id="espectaculo" className="text-center" style={{ marginTop: '30px'}}>
            <div className="container">
                <div className="col-md-10 col-md-offset-1 "  style={{ marginBottom: '30px'}}>
                    
                    <p>Bienvenido a SÓTANO TEATRO. El mejor teatro de producciones independientes de gran calidad. Estamos seguros que te encantará nuestro trabajo. Te invitamos a vivir la experiencia de nuestras increíbles producciones. Ven con tu pareja, con tus amigos o tu familia a pasar momentos memorables, unicos divertidos, profundos y emocionantes con nuestras producciones.</p>
                </div>
                <div className="row">
                    {props.data
                        ? props.data.map((d, i) => (
                            <div
                                key={`${d.title}-${i}`}
                                className="col-xs-12 col-md-6 col-lg-3"
                            >
                                <a href={d.link} target="_blank" rel="noopener noreferrer" style={{ color: 'gray' }} className="espectaculos-link">
                                    <div className="espectaculos-item card">
                                        <img src={d.img} alt={d.title} className="card-img-top" />
                                        <div className="card-body" style={{ padding: '10px' }}>
                                            <h3 className="card-title">{d.title}</h3>
                                            <p className="card-text">
                                                {d.text}
                                                <a href={d.link} target="_blank" rel="noopener noreferrer" style={{ color: 'gray' }}> {d.enlace}</a>
                                            </p>
                                        </div>
                                    </div>
                                </a>

                            </div>
                        ))
                        : "Loading..."}
                </div>
            </div>
        </div>
    );
};
