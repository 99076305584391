import { useState } from "react";
import React from "react";
import Swal from "sweetalert2";
import axios from 'axios';
import WhatsAppIcon from './whatsapp'
import GoogleMap from './mapa'
import { ssrDynamicImportKey } from "vite/runtime";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [cargando, setCargando] = useState(false);

  const clearState = (e) => {
    e.target.elements.name.value = ''
    e.target.elements.email.value = ''
    e.target.elements.message.value = ''
  };

  const handleSubmit = (e) => {
    setCargando(true)
    e.preventDefault();
    // Obtener los valores de los campos del formulario
    const nombre = e.target.elements.name.value;
    const correo = e.target.elements.email.value;
    const mensaje = e.target.elements.message.value;
    //Cambiar estos datos para cada pagina y su respectivo contacto
    const nombreProp = 'SOTANO TEATRO';
    const correoProp = 'contacto@sotanoteatro.com';
    const pagina = 'sotanoteatro.com';

    // Crear un objeto con los datos a enviar
    const data = {
      nombre,
      correo,
      mensaje,
      nombreProp,
      correoProp,
      pagina
    };

    console.log(data)
    // Realizar la solicitud POST utilizando Axios
    axios.post('https://taeconta.com/api/public/api/correos/publicos', data)
      .then(response => {
        // console.log('Correo enviado con éxito:', response.data);
        Swal.fire(
          "Correo enviado exitosamente",
          "Hemos recibido tu mensaje y nos pondremos en contacto contigo lo antes posible. Gracias por ponerte en contacto con nosotros.",
          "success"
        );
        setCargando(false)
        clearState(e)
      })
      .catch(error => {
        console.error('Error al enviar el correo:', error);
        Swal.fire(
          "Error al enviar el correo",
          error.message + ", " + error.response.data.message,
          "error"
        );
        setCargando(false)
        clearState(e)
      });
  };
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Ponte en contacto</h2>
                <p>
                  Por favor, completa el formulario a continuación para enviarnos un correo electrónico y nos pondremos en contacto contigo lo antes posible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Nombre"
                        required
                      // onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                      // onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Mensaje"
                    required
                  // onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                {cargando ? (
                  <p>Cargando...</p>
                ) : (
                  <button type="submit" className="btn btn-custom ">
                    Enviar mensaje
                  </button>
                )}
              </form>
              <GoogleMap />
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Información de contacto</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Dirección
                </span>
                {props.data ? (
                  <a href="https://maps.app.goo.gl/rs3w5ntFNgzVnEu7A" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
                    {props.data.address}
                  </a>
                ) : (
                  "loading"
                )}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Teléfono | {" "} <i className="fa fa-whatsapp"></i>WhatsApp

                </span>{" "}
                {props.data ? (
                  <a href={`https://wa.me/+52${props.data.phone}`} target="_blank" rel="noopener noreferrer" style={{ color: "white" }}>
                    {props.data.phone}
                  </a>
                ) : (
                  "loading"
                )}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? (
                  <a href={`mailto:${props.data.email}`} style={{ color: 'white' }}>{props.data.email}</a>
                ) : (
                  "loading"
                )}
                <br />
                <p>
                  {props.data ? (
                    <a href={`mailto:${props.data.email2}`} style={{ color: 'white' }}>{props.data.email2}</a>
                  ) : (
                    "loading"
                  )}
                </p>
              </p>
            </div>

            <div className="contact-item">
              <p style={{ fontSize: '20px' }}>
                <span>
                  <i className="fa fa-ticket"></i> Boletos disponibles en:
                </span>{" "}
                <a href='https://boletopolis.com/es/evento/4986' target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>entacones.boletopolis.com</a>
                <br />
                <a href='https://boletopolis.com/es/evento/29979' target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>residuos.boletopolis.com</a>
                <br />
                <a href='https://boletopolis.com/es/evento/4158' target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>cuando.boletopolis.com</a>
                <br />
                <a href='https://boletopolis.com/es/evento/17220' target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>eroomies.boletopolis.com</a>
                <br />
                <a href='https://boletopolis.com/es/evento/4047' target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>roomies1.boletopolis.com</a>
              </p>
            </div>


            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-calendar"></i> Horario de servicio:
                </span>{" "}
                <p>Días: Miercoles a Domingo</p>
                <p>Horario: 5:00 pm - 11:00 pm</p>
              </p>
            </div>
            <WhatsAppIcon />

          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a target="_blank" href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : "/"}>
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  {/* <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; Desarrollado por
            <a href="https://tecnologiasadministrativas.com/" rel="nofollow" target="_blank">
              {" "}TAE
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
